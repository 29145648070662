import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const BestLand = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5> Jan 25 | Utica</h5>
                    <h1>The Best Land: Four Hundred Years of Love and Betrayal on Oneida Territory by Susan A. Brewer </h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/bestland.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    Saturday, January 25, 2:00 P.M.
                    <br/>Utica, 1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    <i>The Best Land: Four Hundred Years of Love and Betrayal on Oneida Territory</i>, Susan A. Brewer traces the history of a parcel of land in central New York and the stories of the two families---her own European settler family and the Mohawk/Oneida family of Polly Denny---who called it home. Her talk considers the struggle over this place in war and peace, tales of pioneer progress, and native dispossession. She explores how, as neighbors for centuries, these families experienced dramatic twists and turns over sovereignty, justice, and the land. 
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default BestLand;