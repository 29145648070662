import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const ErieImmigrants = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5> Feb 8 | Utica</h5>

                    <h1>Erie Canal Immigrants </h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/february2025/erieimmigrants.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    Saturday, February 8, 2:00 P.M.
                       <br/>1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    The Immigrants Erie Canal—New York State is a gateway for many immigrants, from the colonial era to today. This program by Jeanne Gostling, from Schoharie Crossing State Historic Site explores the difficulties facing people searching for a new life in America and how the Erie Canal shaped that path.</p>
                    <p>Jeanne Gostling is a career teacher and lifelong learner, born, raised, and still living in upstate New York. Her passion for history and experience in sociological research led her to Schoharie Crossing Historic Site where she provides onsite interpretation and program development. 
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default ErieImmigrants;