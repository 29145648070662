import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from './FadeIn';
import { LoadFadeIn } from './LoadFadeIn';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';

const Home = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);
        
        LoadFadeIn();
        FadeIn();

        // COUNTER NUMBER

        const counters = document.querySelectorAll(".counter");

        counters.forEach((counter) => {
          counter.innerText = "0";
          const updateCounter = () => {
            const target = +counter.getAttribute("data-target");
            const count = +counter.innerText;
            const increment = target / 300;
            if (count < target) {
              counter.innerText = `${Math.ceil(count + increment)}`;
              setTimeout(updateCounter, 1);
            } else counter.innerText = target;
          };
          updateCounter();
        });

        var closeButton = document.getElementById('close-popup');

        closeButton.addEventListener('click', function() {
            var popupBg = document.getElementById('popup-bg');
                popupBg.style.display = 'none';
        });

    }, []);

    return ( 
        <div id="home-page"> 
            <Helmet>
                <title>Oneida County History Center</title>
                <meta name='description' content='Preserving the rich heritage of Oneida County and the greater Mohawk Valley since 1876.' />
            </Helmet>
           <section id="home-hero-section">
                <div id="home-hero-image-body">
                    <img src="assets/home/oneida-history-hero.webp" alt="home-image"/>
                    <div id="home-hero-content">
                        <div class="home-hero-content">
                            <div class="home-hero-content-text">
                                <h1 className='fade-in-up-load'>Oneida County </h1>
                                <h2 className='fade-in-up-load'>History Center</h2>
                                <p className='fade-in-up-load'>
                                Preserving the rich heritage of Oneida County and the greater Mohawk Valley since 1876.                                
                                </p>
                            </div>
                            <a class="fade-in-up-load main-button" href="https://oneidacountyhistory.square.site/donate">Donate</a>
                        </div>
                    </div>
                </div>       
            </section>
            <section id="home-services-section">
                <div className='container'>  
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div class="card">                              
                                <h2 className='counter fade-in-up' data-target="232000"></h2>
                                <p className='fade-in-up'>
                                Number of residents in Oneida County.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div class="card">                                                          
                                <h2 className='counter fade-in-up' data-target="1798"></h2>
                                <p className='fade-in-up'>
                                The year Oneida County was founded by the New York State Legislature.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div class="card">                              
                                <h2 className='counter fade-in-up' data-target="42"></h2>
                                <p className='fade-in-up'>
                                Number of languages spoken in Oneida County.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="home-services-content-text">
                        <h1>Our Mission</h1>
                        <p className='fade-in-up'>
                        To protect and preserve the past of Oneida County, as well as central New York, for present and future generations. 
                        The History Center seeks to make this rich heritage readily available to researchers, families, and students enhancing the community's knowledge and appreciation of its history.                        
                        </p>
                    </div>
                    <a className="main-button" href="/about">Learn More
                    </a>                    
                </div>
            </section>
                <div className='save-date container'>
                    <div className='row legends'>
                        <div className='col-lg-6'>
                            <h1>The Hall of Fame and Living Legends Class of 2024!</h1>
                            <p className='fade-in-up'>
                            The Oneida County Historical Hall of Fame has named four individuals to its 2024 inductee class. The Hall of Famers were inducted alongside the 2024 Richard W. Couper ‘Living Legends’ class. The inductees were honored during the annual Historical Hall of Fame and Living Legends awards celebration at the Irish Cultural Center in Utica on Thursday, October 10, 2024. Learn who the 2024 inductees are below!
                            </p>
                            <a id="join-hunt" className="main-button" href="/news-hall-of-fame-2024">Class of 2024</a>  
                        </div>
                        <div className='col-lg-6'>
                            <img decoding='async' loading='lazy' alt='2024 Historical Hall of Fame' src="assets/2024HistoricalHallofFame.jpg"/>
                        </div>
                    </div>
                </div>
            <section id="home-why-us-section">
                <div id="home-why-us-image-body">
                    <img decoding='async' loading='lazy'  src="assets/home/oneida-1.jpeg" alt="explore local image"/>
                    <div id="home-why-us-content">
                        <div className="home-why-us-content-text">
                            <div className='section-header'> 
                            Explore Local History
                            </div>
                            <h1>Programs & Events</h1>
                            <p className='fade-in-up'>
                            No matter who you are, there's always something new to learn at  Oneida County History Center. Our monthly programs are a great way to experience the unique history that can only be found in Oneida County.
                            </p>
                        </div> 
                    </div>
                </div>
                <div className='home-why-us-items container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div class="card">
                            <h2 className='fade-in-up'>Special Exhibit: Vintage Valentines</h2>
                                <p>
                                January 31 - February 15
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div class="card">
                                <h2 className='fade-in-up'> Erie Canal <br/>Immigrants </h2>
                                <p>
                                Saturday, February 8
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div class="card">
                                <h2 className='fade-in-up'>Make Your Own Valentine Workshop</h2>
                                <p>
                                Saturday, February 15
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='home-programs-button'>
                        <a className="main-button" href="/programs-and-events">View all programs
                        </a>                   
                    </div>
                </div>
            </section>
            <section id="home-about-section">
                <div class="container">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <img decoding='async' loading='lazy'  src="assets/visit/exhibits/lucywatson.jpg"  alt="Essential Democracy"/>
                        </div>
                        <div className='col-lg-6'>
                            <div class="home-about-content">
                                <div class="home-about-content-text">
                                    <div className='section-header'> 
                                        Featured Exhibit
                                    </div>
                                    <h1 className='fade-in-up'>An Essential Feature of Democracy: Lucy Carlile Watson</h1>
                                    <br/>
                                    <p className='fade-in-up'>
                                    Lucy Carlile Watson was born in Utica in 1855. She dedicated her adult life to community service and women’s suffrage causes. This special exhibit at the History Center showcases Watson’s life and the contributions of local women in achieving ...
                                    </p>
                                </div>
                                <a target="_blank" className="main-button" href="/exhibits">Read More 
                                </a>                   
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="home-shop-section">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div class="container home-shop-content">
                                <div class="home-shop-content-text">
                                    <h1 className='fade-in-up'>Shop Local History</h1>
                                    <p className='fade-in-up'>
                                    Visit the History Center's e-Bookstore for a great selection of local history books and merchandise. 
                                    </p>
                                    <p className='fade-in-up'>
                                    Our entire inventory is available to purchase online with curbside pickup available! 
                                    </p>
                                </div>
                                <a target="_blank" className="main-button-white" href="https://oneidacountyhistory.square.site/">Shop Now
                                </a>                   
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <img decoding='async' loading='lazy'  src="assets/home/bookstore_2024.jpg" alt="Shop"/>
                        </div>
                    </div>
            </section>
            <section id="home-news-section">
                <div className='container'>
                    <div className='home-news-header-text'>
                        <div className='section-header'> 
                            News
                        </div>
                        <h1>Latest Events</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div class="card news-card">
                                <img decoding='async' loading='lazy'  class="card-img-top" src="assets/halloffame2024.jpg" />
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Announcing the Historical Hall of Fame and Living Legends Class of 2024!</h4>
                                    <p class="card-text fade-in-up">
                                    The Oneida County Historical Hall of Fame has named four individuals to its 2024 inductee class: Dr. John Cochran, Army Surgeon General and physician to George Washington; Charles A. Gaetano, entrepreneur and philanthropist; Chauncey Goodrich, Godfather of all modern potatoes; and ...
                                    </p>
                                    <a href="/news" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                                </div>
                            </div>
                        </div>  
                        <div className="col-lg-6">
                            <div class="card news-card">
                                <img decoding='async' loading='lazy' alt='news event' class="card-img-top" src="/assets/mvgives_2024.jpg" />
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">History Center to Participate in Mohawk Valley Gives!</h4>
                                    <p class="card-text fade-in-up">
                                    On September 20, the Community Foundation of Herkimer and Oneida Counties and UpMobility Foundation will host the third annual “Mohawk Valley Gives,” a 24-hour community-wide giving day to benefit nonprofit organizations serving the ...
                                    </p>
                                    <a href="/news-mv-gives-2024" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </section>
            <div id="popup-bg">
                <div id="popup" method="post" netlify-honeypot="bot-field" data-netlify="true">
                    <div className='popup-body'>
                        <div id="popup-right" >
                            <div className='popup-question'>
                                <button type="button" id="close-popup">X</button>
                            </div>
                            <img src='/assets/home/popups/holidays2024.png'/>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    );
}

export default Home;