import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const NaacpFeb = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5> Feb 1 | Utica</h5>

                    <h1>Utica/Oneida County Branch NAACP Annual Meeting  </h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/naacp_feb.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    Saturday, Feb 1, 1:00 -3:00  P.M.
                       <br/>Utica, 1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Join the Utica/Oneida County Branch NAACP to celebrate Black History Month 2025 with its annual program hosted by the History Center. This event will follow the 2025 national theme, African American and Labor. This program is available in person and virtually via Zoom.  To resister for the program via zoom, follow this <a target='_blank' href="https://us06web.zoom.us/meeting/register/GSVPF_LkSr6RdP3qrRZYqg">link</a>.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default NaacpFeb;