import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from '../FadeIn';
import { LoadFadeIn } from '../LoadFadeIn';
import ReactGA from 'react-ga';



const Programs = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="programs-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/visit/programs/TEMP-programs.webp' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className="fade-in-up-load">Programs & Events</h1>
                    <h4 className="fade-in-up-load">
                    No matter who you are, there's always something new to learn at Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <h2>Upcoming Events</h2>
                    <div className='row'>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/valentines.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Special Exhibit: Vintage Valentines </h4>
                                    <p class="card-text fade-in-up">
                                    January 31 - February 15
                                    </p>
                                    <a href="/vintage-valentines" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/naacp_feb.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Utica/Oneida County Branch NAACP Annual Meeting    </h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, February 1
                                    </p>
                                    <a href="/naacp-february" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/february2025/erieimmigrants.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Erie Canal Immigrants </h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, February 8
                                    </p>
                                    <a href="/erie-immigrants" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/february2025/valetineworkshop.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Make Your Own Valentine Workshop</h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, February 15
                                    </p>
                                    <a href="/valentine-workshop" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/february2025/storyhourfeb.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Story Hour at the History Center  </h4>
                                    <p class="card-text fade-in-up">
                                    Wednesday, February 19
                                    </p>
                                    <a href="/story-hour-feb" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/february2025/greaterutica2.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Ten Years of Greater Utica Magazine </h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, February 22
                                    </p>
                                    <a href="/greater-utica" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='programs-vids-section container'>
                <div className='container calendar'>
                    <div className='row top'>
                        <div className='col-lg-8'>
                            <h2>View the <span>calendar</span> for more upcoming events and programs!</h2>
                        </div>
                        <div className='col-lg-4'>
                        <a className="video-button" 
                                href="/events"
                                aria-label='calendar'
                                target='_blank'
                            >OCHC Calendar</a>                          
                        </div>
                    </div>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <h2>Past Events</h2>
                    <div className='row'>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/storyhour_jan.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Story Hour at the History Center </h4>
                                    <p class="card-text fade-in-up">
                                    Wednesday, January 15
                                    </p>
                                    <a href="/story-hour-january" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/bts_jan.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Behind-the-Scenes Tours      </h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, January 18
                                    </p>
                                    <a href="/bts-january" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/bestland.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up"><i>The Best Land</i> by Susan A. Brewer</h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, January 25
                                    </p>
                                    <a href="/best-land" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>
            <section className='programs-vids-section'>
                <div className='container'>
                    <div className='row top'>
                        <div className='col-lg-6'>
                            <h2>Learn More on Our Youtube Channel!</h2>
                        </div>
                        <div className='col-lg-6'>
                            <a className="video-button" 
                                href="https://www.youtube.com/channel/UCxL6DtlD7yI6ioAsD7z9Oxg"
                                aria-label='Newsletter'
                                target='_blank'
                            >OCHC Youtube</a>                        
                        </div>
                    </div>
                    <div className="row"> 
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/rdlMuOtOMmI?si=IXvgAIKAJMp3rl0M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>    
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/T2pI7lArnak?si=qSFgQjH0ki4NeiMB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                        
                        </div> 
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/8KSf7v-GqDc?si=h4axbzZ7BCaLSqMO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div> 
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/8H29_BOnHY4?si=5zBRdYM2petEqePP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>   
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Programs;